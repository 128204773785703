:root {
    --coming-soon-card-bg: #F5F6F9;
    --coming-soon-switch-color: #586E87;
    --coming-soon-switch-text: #B9C5CD;
    --primary-color: #000;
    --primary-text-color: #fff;
    --secondary-color: #fff;
    --secondary-text-color: #000;
    --tertiary-color: #ECEFF4;
    --lemon: #DEF8CB;
    --pink: #FFD8D7;
    --blue: #DBD9F5;
    --off-white: #F8F8F8;
    --white-smoke: #F8F8F8;
    --lily: #DAEBE3;
    --gable: #1B3233;
    --light-grey: #DDDDDD;
    --current-bg: #00000000;
    --delay-menu: 1;
    --black: #000;
    --white: #fff;
    --img-height: 0;
}

.swiper-hero-section .swiper-wrapper, .testimonial .swiper-wrapper {
    transition-timing-function: linear !important;
}

.testimonial .swiper-slide {
    height: unset !important;
}


body, #root {
    background-color: var(--tertiary-color);
    min-height: 100vh;
}
#home{
    overflow: hidden;
}

.overlay-gradient {
    --gd-color: 248 248 248;
    height: 100px;
    width: 100%;
    left: 0;
    background: linear-gradient(rgb(var(--gd-color)), rgb(var(--gd-color)/0));
    z-index: 20;
}

.overlay-gradient.top {
    top: -1px;
}

.overlay-gradient.bottom {
    background: linear-gradient(to top, rgb(var(--gd-color)), rgb(var(--gd-color)/0));
    bottom: -1px;
}


.overlay-gradient-testimonial {
    --gd-color: 248 248 248;
    top: 0;
    height: 100%;
    width: max(50px, 15%);
    z-index: 20;
    pointer-events: none;
}

.overlay-gradient-testimonial.to-left {
    background: linear-gradient(90deg,rgb(var(--gd-color)), rgb(var(--gd-color)/0));
    left: 0;
}

.overlay-gradient-testimonial.to-right {
    background: linear-gradient(to left, rgb(var(--gd-color)), rgb(var(--gd-color)/0));
    right: 0;
}

body, #root {
    width: 100%;
    min-height: 100svh;
}

.secondary-gradiant {
    background: linear-gradient(180deg, #def8cb, rgba(222, 248, 203, 0.1) 19%, rgba(222, 248, 203, 0) 50.5%, rgba(222, 248, 203, 0.1) 100%);
}

.btn {
    background-color: var(--btn-bg-color) !important;
    color: var(--btn-text-color) !important;;
    border-width: 1px;
    border-style: solid;
    border-color: var(--btn-border-color) !important;
    transition: all 300ms ease-in-out;
    letter-spacing: 1px;
    font-size: 16px;
}

.btn.btn-white {
    --btn-bg-color: var(--secondary-color);
    --btn-border-color: var(--white-smoke);
    --btn-text-color: var(--black);
}

.btn.btn-white:hover {
    --btn-bg-color: var(--black);
    --btn-border-color: var(--black);
    --btn-text-color: var(--white);
}

.btn-primary {
    --btn-bg-color: var(--primary-color);
    --btn-text-color: var(--primary-text-color);
    --btn-border-color: var(--primary-color)
}

.btn-primary:hover, .btn-primary.active {
    --btn-bg-color: transparent;
    --btn-text-color: var(--primary-color);
    --btn-border-color: var(--primary-color);
}


.btn-primary-outline {
    --btn-bg-color: transparent;
    --btn-text-color: var(--primary-color);
    --btn-border-color: var(--primary-color);
}


.btn-primary-outline:hover, .btn-primary-outline.active {
    --btn-bg-color: var(--primary-color);
    --btn-text-color: var(--secondary-color);
    --btn-border-color: var(--primary-color);
}

.btn-secondary {
    --btn-bg-color: var(--secondary-color);
    --btn-text-color: var(--secondary-text-color);
    --btn-border-color: var(--secondary-color);
}

.btn-secondary:hover, .btn-secondary.active {
    --btn-bg-color: transparent;
    --btn-text-color: var(--secondary-text-color);
    --btn-border-color: var(--secondary-text-color);
}

.btn-secondary-outline {
    --btn-bg-color: transparent;
    --btn-text-color: var(--secondary-text-color);
    --btn-border-color: var(--secondary-text-color);
}

.btn-secondary-outline:hover, .btn-secondary-outline.active {
    --btn-bg-color: var(--secondary-text-color);
    --btn-text-color: var(--secondary-color);
    --btn-border-color: var(--secondary-text-color);
}

.ant-switch-checked.coming-soon-switch {
    background-color: transparent !important;
}

.coming-soon-switch .ant-switch-handle::before {
    background-color: var(--coming-soon-switch-color);
    border-radius: 50px;
}

.coming-soon-switch.ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 38px);
}

.coming-soon-switch .ant-switch-handle {
    top: 50%;
    translate: 5px -50%;
    width: 26px;
    height: 26px;
}


.hamburger {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hamburgerRotate.active {
    transform: rotate(45deg);
}

.hamburgerRotate180.active {
    transform: rotate(180deg);
}

.line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #000;
    stroke-width: 5.5;
    stroke-linecap: round;
}

.hamburger3 .top {
    stroke-dasharray: 40 130;
}

.hamburger3 .middle {
    stroke-dasharray: 40 140;
}

.hamburger3 .bottom {
    stroke-dasharray: 40 205;
}

.hamburger3.active .top {
    stroke-dasharray: 75 130;
    stroke-dashoffset: -63px;
}

.hamburger3.active .middle {
    stroke-dashoffset: -102px;
}

.hamburger3.active .bottom {
    stroke-dasharray: 110 205;
    stroke-dashoffset: -86px;
}

.menuLink::after {
    transition: width 0.5s cubic-bezier(0.63, 0.03, 0.21, 1);
}

.menuLink.active {
    width: 0;
}

.delay_menu {
    transition-delay: 0ms;
}

.hammenu.open .delay_menu {
    transition-delay: calc(700ms * var(--delay-menu, 1));
}

/* eslint-disable-next-lin*/
.wrapper-about:before {
    background: linear-gradient(to top, var(--current-bg) 20%, transparent);
}

.portfolio-card:hover .portfolio-link {
    background-color: var(--tertiary-color);
    color: var(--black);
}

.portfolio-card img {
    transition: all calc(var(--img-height, 0) * 0.0035s) ease-in-out;
}

.portfolio-card:hover img {
    top: calc(var(--img-height, 0) * -1px);
}

.dtr-icon-btn--icon-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-flex;
    overflow: hidden;
}

.dtr-icon-btn--icon {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-flex;
    transition: transform 0.4s ease-in, opacity 0.4s ease-in;
}

.dtr-icon-btn--hover-icon {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-flex;
    transform: translate(-50px, 50px);
    transition: transform 0.4s ease-in, opacity 0.4s ease-in;
}

.portfolio-card:hover .dtr-icon-btn--hover-icon {
    transform: translate(0, 0);
}

.portfolio-card:hover .dtr-icon-btn--icon {
    transform: translate(50px, -50px);
}


.progress-wrap {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 44px;
    width: 44px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
    mix-blend-mode: difference;
}

.progress-wrap svg.progress-circle path{
    transition: stroke-dashoffset 10ms linear 0s;
    stroke-dasharray: 307.919, 307.919;
    stroke-dashoffset: 310;
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.progress-wrap svg.progress-circle path {
    fill: none;
}

.progress-wrap svg.progress-circle path {
    stroke: #ccc;
    stroke-width: 3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.spinner {
    animation: rotator 1.4s linear infinite;
    position: relative;
    top: -1px;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}
.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}

/* Loading -- -- */

body.loaded {
    overflow: hidden !important;
    height: 100% !important;
}

.loader-wrap {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #f5f7f9; /* #f5f7f9 */
    z-index: 99999999999999;
}

.loader-wrap svg {
    position: absolute;
    top: -2px;
    width: 100vw;
    height: 110vh;
    fill: #000; /* #f5f7f9; */
    z-index: 99999999999999;
}


.loader-wrap .loader-wrap-heading .load-text {
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 15px;
    text-transform: uppercase;
    z-index: 20;
    color: #000;
}

.load-text span {
    -webkit-animation: loading 1s infinite alternate;
    animation: loading 1s infinite alternate;
}

.load-text span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.load-text span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.load-text span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.load-text span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.load-text span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.load-text span:nth-child(6) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.load-text span:nth-child(7) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

@-webkit-keyframes loading {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* Loading -- End -- */

@media (width < 550px) {

    .loader-wrap svg {
        width: 150vw;
        height: 110vh;
    }

}

@media (width < 450px) {
    .schedule-call-wrapper {
        flex-direction: column;
    }

    .schedule-call-wrapper > button {
        width: 100%;
    }

    .project-filter-btn {
        width: calc(50% - 30px);
    }

    .project-filter-btn-circle {
        width: 100%;
        text-align: center;
    }

    .project-filter-btn-circle button {
        padding-left: 20px !important;
        padding-right: 20px !important;;
    }

    .project-filter-btn-circle .txt {
        display: block;
    }
}
