@tailwind base;
@tailwind components;
.btn {
    @apply px-6 rounded-full py-2 w-auto h-auto;
}

.btn.btn-circle {
    @apply px-2 rounded-full;
}

.btn.btn-sm {
    @apply text-sm;
}

.btn.btn-lg {
    @apply text-[18px] px-7 py-2.5;
}


*:before, *:after {
    @apply transition-all ease-in-out duration-300;
}

.animate-300 {
    @apply transition-all duration-300 ease-in-out;
}

.animate-500 {
    @apply transition-all duration-500 ease-in-out;
}

.animate-700 {
    @apply transition-all duration-700 ease-in-out;
}

.menu-elm-hide-20 {
    @apply relative top-20 pointer-events-none opacity-0 transition-all duration-500 ease-in-out;
}

.menu-elm-hide-10 {
    @apply relative top-10 pointer-events-none opacity-0 transition-all duration-500 ease-in-out;
}

.logo-close-menu {
    @apply -top-32 opacity-0 pointer-events-none transition-all duration-500 ease-in-out;
}

.hammenu.open .logo-close-menu {
    @apply top-0 opacity-100 pointer-events-auto;
}

.hammenu.open .menu-elm-hide-20 {
    @apply top-0 opacity-100 pointer-events-auto;
}

.hammenu.open .menu-elm-hide-10 {
    @apply top-0 opacity-100 pointer-events-auto;
}

.hammenu {
    @apply delay-500 bg-tertiary-900 top-0 -left-full fixed w-full h-screen z-50 transition-all duration-700 ease-[cubic-bezier(1,0,0.55,1)];
}

.hammenu.open {
    @apply delay-0;
}

.hammenu.open {
    @apply left-0 pointer-events-auto;
}

.menuLink {
    @apply relative text-black/40 after:content-[attr(data-text)] after:absolute after:top-0 after:left-0 after:w-full after:overflow-hidden after:text-black after:transition-all after:duration-500 after:ease-[cubic-bezier(0.63,0.03,0.21,1)] ;
}

.menuLink.disable {
    @apply after:w-0;
}

.heading-transition {
    @apply transition-all ease-in-out duration-700
}

.heading-xxl {
    @apply text-7xl leading-[6rem];
}


.heading-xl {
    @apply text-6xl leading-[5rem];
}

.heading-lg {
    @apply text-5xl leading-[3.75rem];
}


.heading {
    @apply text-4xl leading-[3rem] ;
}

.sub-heading {
    @apply text-2xl;
}

.para-xlg {
    @apply text-[20px] leading-8;
}

.para-lg {
    @apply text-[18px] leading-6;
}

.para-lg-thin {
    @apply text-[18px] leading-6 font-light;
}

.link {
    @apply relative inline-block before:block before:absolute before:left-0 before:top-full before:bg-black before:w-0 before:h-[1px] hover:before:w-full;
}

.form-error{
    @apply text-red-700 mt-2.5 mx-2.5;
}

@tailwind utilities;
